<template>
  <div style="display:flex;flex-direction: column;">
    <div id="context" class=" information page-container" v-if="data">
      <div class="information-header">
        <div class="title" v-if="!!data.message_title">
          {{ data.message_title }}
        </div>
        <div class="sub-title" v-if="!data.on_delete">
          <span class="time">发布时间：{{ processDate(data.published_at) }}</span>
          <span>文章来源：{{ data.source_from||data.valley_name }}</span>
          <span class="look-count" v-if="data.look_up_count">当前浏览：{{ data.look_up_count}}</span>
        </div>
      </div>
      <div id="print-content" class="information-content" >
        <div v-html="data.content" v-if="!data.on_delete" />
        <div  v-else>
          <PageError />
        </div>
      </div>
    </div>
   <template v-if="!data.on_delete">
      <div class="information-additional page-container">
       <div
          style="margin-top: 15px"
          v-if="!!data.upload_file && !!data.upload_file.length "
        >
          附件下载：
          <p v-for="item in data.upload_file" :key="item.url">
            <a :href="item.url">{{ item.name }}</a>
          </p>
        </div>
        <!-- <div class="update-at">更新于：{{ processDate(data.updated_at,"YYYY/MM/DD HH:mm:ss") }}</div> -->
    </div>
    <div>
      <button class="print-btn" v-print="printObj">打印</button>
    </div>
   </template>
  </div>
</template>
<script>
// let logo = require('assets/mainLogo.png')
// import jweixin from "constants/jweixin-1.6.0.js"
import PageError from '../error_404.vue';
export default {
  name: "informationDetail",
  components:{PageError},
  data: () => ({
    data: null,
    showBtn:true,
    closeDownload:false,
    printObj: {
      id:"context",
      popTitle:'',
      extraHead: '<meta http-equiv="Content-Language"content="zh-cn" />,<meta name="viewport" content="width=device-width"/>,<style> #context{width:100%} #print-content{margin-top:0;box-shadow: 0px 0px 0px #fff;} </style>',
      extraCss: "https://cdn.bootcdn.net/ajax/libs/animate.css/4.1.1/animate.compat.css, https://cdn.bootcdn.net/ajax/libs/hover.css/2.3.1/css/hover-min.css",
      beforeOpenCallback (vue) {
        console.log('打开之前',vue)
      },
      openCallback (vue) {
        console.log('执行了打印',vue)
      },
      closeCallback (vue) {
        console.log('关闭了打印工具',vue)
      }
    }
    // printPage:{
    //   id: "context",
    // }
  }),
  created() {
    this.getData();

  },
  mounted() {
    this.$nextTick(() => {
      this.$axiosRequest({
        method:"PUT",
        name: "getSavePageView",
        ids: [this.$route.params.id],
      })
      .then(res=>{
        console.log(res);
      })
      .catch(err=>{
        console.log(err);
      })
    });
  },
  methods: {
    processDate(date,format){
      return this.$dayjs(date).format(format||'YYYY/MM/DD')
    },
    getData() {
      this.$axiosRequest({
        name: "getValleyMessageDetail",
        ids: [this.$route.params.id],
      })
        .then((res) => {
          // console.log(res);
          if (res.status === 200) {
            this.data = res.data;
            document.title = res.data.message_title;
            // this.printObj.url = "https://www.qzrcw.com/information/detail/"+res.data.valley_message_id
          }
          
          // wx.ready(function () {
          //   wx.updateTimelineShareData({
          //     title: res.data.message_title, // 分享标题
          //     // imgUrl: logo, // 分享图标
          //     success: function () {
          //       // 设置成功
          //       console.log("分享给朋友及分享到QQ");
          //     },
          //   });
          // });
          // wx.ready(function () {
          //   wx.updateAppMessageShareData({
          //     title: res.data.message_title, // 分享标题
          //     imgUrl: logo, // 分享图标
          //     success: function () {
          //       // 设置成功
          //       console.log("分享到朋友圈及分享到QQ空间");
          //     },
          //   });
          // });
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
  computed: {
  },
  watch: {},
};
</script>
<style lang="scss">
@import "constants/style/public";
.information {
  text-align: left;
  .information-header {
    background-color: $defaultBg;
    padding: 20px;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    box-shadow: 0px 0px 18px $shadowColor;
    .title {
      font-size: $extraLarge;
      font-weight: $weight-medium;
      padding-bottom: 16px;
      color: $titleColor;
    }
    .sub-title {
      color: $cardsubTextColor;
      font-size: $medium;
      color: $cardsubTextColor;
      .time {
        margin-right: 50px;
      }
      .look-count{
        margin-left: 50px;
      }
    }
    
  }
  .information-content {
    text-align: auto;
    min-height: 400px;
    background-color: $defaultBg;
    margin: 26px 0;
    padding: 20px;
    box-shadow: 0px 0px 18px $shadowColor;
    table,
    th,
    td {
      border: 1px solid #333;
    }
    table {
      border-collapse: collapse;
    }
    th,
    td {
      padding: 10px;
    }
    .ql-align-right {
      text-align: right;
    }
    .ql-align-center {
      text-align: center;
    }
  }
}

.print-btn{
  cursor: pointer;
  margin: 40px 0 90px;
}
.information-additional{
  background-color: $defaultBg;
  text-align: left;
  box-shadow: 0px 10px 10px $shadowColor;
  padding: 0 20px 20px;
  .update-at{
    text-align: center;
    font-size: var(--little,12px);
    color: var(--promptColor,#999);
    
  }
}
</style>